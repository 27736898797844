import { PaymentElement, AddressElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "@chakra-ui/react";
import { paymentResposne } from "../../modules/paymentModule";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      //   confirmParams: {
      //     // Make sure to change this to your payment completion page
      //     return_url: `${window.location.origin}/`,
      //   },
      redirect: "if_required",
    });

    if (paymentIntent) {
      paymentResposne(paymentIntent, (error, details) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (details) {
          window.location.replace("techeye://ThankYou");
        }
      });
    }

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured. Please try again.");
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form">
      <PaymentElement />
      <AddressElement options={{ mode: "billing" }} />
      <Button
        margin="10px 0px"
        disabled={isProcessing || !stripe || !elements}
        id="submit"
        onClick={handleSubmit}
      >
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </Button>
      {/* Show any error or success messages */}
      {message && <div>{message}</div>}
    </form>
  );
}
