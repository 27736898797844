import React, { useState } from "react";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  Hide,
  Image,
  Input,
  Show,
  Spacer,
  Text,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";

import moment from "moment/moment";
import FeedModal from "../FeedModal/FeedModal";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { postRatingAndComment } from "../../modules/homepageModule";
import { useSelector } from "react-redux";

const FeedCard = ({ feed, refresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector((state) => state?.user?.user);
  const token = useSelector((state) => state?.user?.token);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const postRatingNComment = () => {
    if (!rating || !comment) {
      alert("Please enter both rating and comment.");
      return;
    }
    postRatingAndComment(
      user.user_id,
      feed.id,
      rating,
      comment,
      "report",
      token,
      (error, details) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (details) {
          alert("Posted succesfully!");
          refresh();
        }
      }
    );
  };

  return (
    <Flex
      padding="10px 0px"
      background="#FFFFFF"
      borderRadius="10px"
      marginBottom="10px"
      flexDirection="column"
    >
      <Flex width="100%">
        <Flex flexDirection="column" marginLeft="25px">
          <Text fontSize="18px" fontWeight="600">
            {feed?.User_Property?.property_name}
          </Text>
          <Text fontSize="14px" fontWeight="500">
            {feed?.User_Property?.address}
          </Text>
          <Text fontSize="12px" fontWeight="500" color="rgba(0, 0, 0, 0.6)">
            {moment(feed?.report_date).format("MMMM Do YYYY")}
          </Text>
        </Flex>
        <Spacer />
        <Hide below="sm">
          {feed?.report_subscriber_rating || rating ? (
            <Flex
              marginRight="25px"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Flex>
                {Array.apply(null, {
                  length: feed?.report_subscriber_rating || rating,
                }).map((e, i) => (
                  <Icon color="#fcd53f" as={AiFillStar} />
                ))}
              </Flex>
              <Text>
                <b>Your Rating</b>
              </Text>
            </Flex>
          ) : (
            <Flex
              marginRight="25px"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Flex>
                {Array.apply(null, {
                  length: 5,
                }).map((e, i) => (
                  <Icon
                    css={{ cursor: "pointer" }}
                    onClick={() => setRating(i + 1)}
                    as={AiOutlineStar}
                  />
                ))}
              </Flex>
              <Text>Rate this work</Text>
            </Flex>
          )}
        </Hide>
      </Flex>
      <Flex margin="10px 0px">
        {feed?.Reports_Details.length >= 3 ? (
          <Flex width="100%" onClick={onOpen} css={{ cursor: "pointer" }}>
            <Flex width="69%">
              <Image
                height="400px"
                width="100%"
                src={
                  feed?.Reports_Details.filter(
                    (item) => item?.Property_Medium?.media_type === "Photo"
                  )[0]?.Property_Medium?.media_url
                }
              />
            </Flex>
            <Spacer />
            <Flex flexDirection="column" width="30%">
              <Flex>
                <Image
                  height="195px"
                  width="100%"
                  src={
                    feed?.Reports_Details.filter(
                      (item) => item?.Property_Medium?.media_type === "Photo"
                    )[1]?.Property_Medium?.media_url
                  }
                />
              </Flex>
              <Spacer />
              <Flex>
                <Image
                  height="195px"
                  width="100%"
                  src={
                    feed?.Reports_Details.filter(
                      (item) => item?.Property_Medium?.media_type === "Photo"
                    )[2]?.Property_Medium?.media_url
                  }
                />
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <Image
            height="400px"
            width="100%"
            src={feed?.Reports_Details[0]?.Property_Medium?.media_url}
          />
        )}
      </Flex>
      <Flex margin="0px 25px" flexDirection="column">
        <Text
          fontSize="18px"
          fontWeight="600"
          color="#0A3E96"
          marginBottom="10px"
        >
          {`${feed?.Reports_Details.length} photos & videos | Enabler - ${feed?.Enabler?.full_name}`}
        </Text>
        <Divider />
        <Flex marginTop="10px" flexDirection="column">
          <Show below="sm">
            {feed?.report_subscriber_rating || rating ? (
              <Flex marginRight="25px" marginBottom="10px" alignItems="center">
                <Text>
                  <b>Your Rating : </b>
                </Text>
                <Flex>
                  {Array.apply(null, {
                    length: feed?.report_subscriber_rating || rating,
                  }).map((e, i) => (
                    <Icon color="#fcd53f" as={AiFillStar} />
                  ))}
                </Flex>
              </Flex>
            ) : (
              <Flex marginRight="25px" marginBottom="10px" alignItems="center">
                <Text>Rate :&nbsp;</Text>
                <Flex>
                  {Array.apply(null, {
                    length: 5,
                  }).map((e, i) => (
                    <Icon
                      css={{ cursor: "pointer" }}
                      onClick={() => setRating(i + 1)}
                      as={AiOutlineStar}
                      color="#fcd53f"
                    />
                  ))}
                </Flex>
              </Flex>
            )}
          </Show>
          {feed?.report_subscriber_comment ? (
            <Flex>
              <Text>
                <b>Your Comment : </b>
                {feed?.report_subscriber_comment}
              </Text>
            </Flex>
          ) : (
            <Flex>
              <FormControl>
                <Input
                  paddingLeft="5px"
                  type="text"
                  placeholder="Write a  comment ..."
                  onChange={(e) => setComment(e.target.value)}
                />
              </FormControl>
              <Button
                marginLeft="10px"
                borderRadius="10px"
                background="#FFFFFF"
                onClick={() => postRatingNComment()}
              >
                POST
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
      <FeedModal
        type="feed"
        onClose={onClose}
        isOpen={isOpen}
        feed={feed}
        refresh={refresh}
      />
    </Flex>
  );
};

export default FeedCard;
