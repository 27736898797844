import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Hide,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
} from "@chakra-ui/react";
import techEyeLogo from "../../assets/techEyeLogo.png";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BellIcon } from "@chakra-ui/icons";
import { getNotifications } from "../../modules/commonModule";
import moment from "moment";
import { logout } from "../../redux/features/userSlice";

const Header = () => {
  const location = useLocation();
  const user = useSelector((state) => state?.user?.user);
  const token = useSelector((state) => state?.user?.token);
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.user_id) {
      getNotifications(user?.user_id, token, (error, details) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (details) {
          setNotifications(details.data);
        }
      });
    }
  }, []);
  return (
    <Box
      display={
        location.pathname === "/" ||
        location.pathname.split("/")[1] === "payments"
          ? "none"
          : "flex"
      }
      height="10vh"
      borderBottom="1px solid rgba(226, 232, 240, 0.8)"
      padding="10px"
      alignItems="center"
      background="#FFFFFF"
    >
      <Flex marginLeft="5px" width={["35vw", "35vw", "35vw", "15vw"]}>
        <Image src={techEyeLogo} alt="techEyeLogo" />
      </Flex>
      <Spacer />
      <Flex alignItems="center">
        <Popover>
          <PopoverTrigger>
            <Button marginRight="10px" background="none">
              <BellIcon fontSize="24px" />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Recent Notifications</PopoverHeader>
            <PopoverBody height="400px" overflowY="auto">
              {notifications?.map((item) => (
                <Flex
                  flexDirection="column"
                  padding="10px 0px"
                  borderBottom="1px solid gray"
                >
                  <Text fontWeight="600">{item.notification_title}</Text>
                  <Text>{item.notification_body}</Text>
                  <Text
                    fontSize="12px"
                    fontWeight="500"
                    color="rgba(0, 0, 0, 0.6)"
                    textAlign="right"
                  >
                    {moment(item?.createdAt).format("MMMM Do YYYY, hh:mm A")}
                  </Text>
                </Flex>
              ))}
            </PopoverBody>
          </PopoverContent>
        </Popover>

        <Hide below="sm">
          <Text marginRight="20px" fontSize="20px">
            {user?.subscriber_name}
          </Text>
        </Hide>

        <Box background="black" height="40px" width="40px" borderRadius="50%">
          {user?.profile_image ? (
            <Popover>
              <PopoverTrigger>
                <Image
                  borderRadius="50%"
                  height="40px"
                  width="40px"
                  src={user?.profile_image}
                  css={{ cursor: "pointer" }}
                />
              </PopoverTrigger>
              <PopoverContent width="100px">
                <PopoverArrow />
                <PopoverBody>
                  <Text
                    onClick={() => {
                      dispatch(logout());
                    }}
                    css={{ cursor: "pointer" }}
                  >
                    Log Out
                  </Text>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            <Popover>
              <PopoverTrigger>
                <Center css={{ cursor: "pointer" }} height="40px" width="40px">
                  <Text color="white">NA</Text>
                </Center>
              </PopoverTrigger>
              <PopoverContent width="100px">
                <PopoverArrow />
                <PopoverBody>
                  <Text
                    onClick={() => {
                      dispatch(logout());
                    }}
                    css={{ cursor: "pointer" }}
                  >
                    Log Out
                  </Text>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
