import React, { useState } from "react";
import { Flex, Hide, Text, Icon } from "@chakra-ui/react";
import {
  AiOutlinePicLeft,
  AiOutlineHome,
  AiOutlineFilePdf,
  AiOutlineSwitcher,
} from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

const sidebarContent = [
  { name: "My Feed", icon: AiOutlinePicLeft, route: "/home" },
  { name: "Properties", icon: AiOutlineHome, route: "/properties" },
  { name: "View Reports", icon: AiOutlineFilePdf, route: "/reports" },
  { name: "Comparison", icon: AiOutlineSwitcher, route: "/comparison" },
];

const Sidebar = () => {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  return (
    <Hide below="md">
      <Flex
        display={
          location.pathname === "/" ||
          location.pathname.split("/")[1] === "payments"
            ? "none"
            : "flex"
        }
        width="calc(20vw - 30px)"
        position="fixed"
        height="calc(90vh - 40px)"
        borderRadius="10px"
        padding="10px 0px"
        margin="20px 10px 20px 20px"
        background="#FFFFFF"
        flexDirection="Column"
      >
        {sidebarContent.map((item) => (
          <Flex
            as={Link}
            to={item.route}
            key={item.name}
            width="calc(20vw - 30px)"
            alignItems="center"
            height="40px"
            background={
              active === item.route ? "rgba(38, 166, 209, 0.07)" : "white"
            }
            borderLeft={active === item.route ? "5px solid #26A6D1" : "white"}
            borderRadius={active === item.route ? "4px" : "0px"}
            color={active === item.route ? "#26A6D1" : "#707070"}
            css={{
              ":hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => setActive(item.route)}
          >
            <Icon margin="0px 15px" as={item.icon} />
            <Text fontSize="18px">{item.name}</Text>
          </Flex>
        ))}
      </Flex>
    </Hide>
  );
};

export default Sidebar;
