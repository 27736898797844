import React, { useState } from "react";
import { Flex, Image, Text, Icon, useDisclosure } from "@chakra-ui/react";
import { AiFillFolderOpen } from "react-icons/ai";

import moment from "moment/moment";
import FeedModal from "../FeedModal/FeedModal";

const PropertyCard = ({ feed, refresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentReport, setCurrentReport] = useState([]);
  const handleReportClick = (report) => {
    setCurrentReport(report);
    onOpen();
  };
  return (
    <Flex
      padding="20px"
      background="#FFFFFF"
      borderRadius="10px"
      marginBottom="10px"
      flexDirection="column"
    >
      <Flex flexWrap="wrap">
        <Flex width={["100%", "100%", "50%", "50%"]}>
          <Image
            height="400px"
            width="100%"
            src={feed?.Property_Media[0]?.media_url}
          />
        </Flex>
        <Flex
          width={["100%", "100%", "50%", "50%"]}
          flexDirection="column"
          paddingLeft="10px"
        >
          <Text fontSize="26px" marginBottom="20px">
            {feed?.property_name}
          </Text>
          <Flex marginBottom="5px">
            <Text fontSize="18px">{feed?.description}</Text>
          </Flex>
          <Flex marginBottom="5px">
            <Text fontSize="18px" fontWeight="600">
              Property Type:
            </Text>
            <Text fontSize="18px" color="#686868">
              &nbsp;{feed?.Property_Type?.type}
            </Text>
          </Flex>
          <Flex marginBottom="5px">
            <Text fontSize="18px" fontWeight="600">
              Plot Area:
            </Text>
            <Text fontSize="18px" color="#686868">
              &nbsp;{feed?.plot_area}
            </Text>
          </Flex>
          <Flex marginBottom="5px">
            <Text fontSize="18px" fontWeight="600">
              Built-up Area:
            </Text>
            <Text fontSize="18px" color="#686868">
              &nbsp;{feed?.built_area}
            </Text>
          </Flex>
          <Flex marginBottom="5px">
            <Text fontSize="18px" fontWeight="600">
              Address:
            </Text>
            <Text fontSize="18px" color="#686868">
              &nbsp;{feed?.address}
            </Text>
          </Flex>
          <Flex marginBottom="5px">
            <Text fontSize="18px" fontWeight="600">
              Reporting Frequency:
            </Text>
            <Text fontSize="18px" color="#686868">
              &nbsp;{feed?.frequency}
            </Text>
          </Flex>
          <Flex>
            <Text fontSize="18px" fontWeight="600">
              Subscription Duration:
            </Text>
            <Text fontSize="18px" color="#686868">
              &nbsp;{feed?.duration}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex marginTop="10px" flexDirection="column">
        <Text fontSize="24px" fontWeight="600" marginBottom="5px">
          Gallery
        </Text>
        <Flex
          borderRadius="6px"
          background="#E2E8F0"
          padding="0px 10px 10px 10px"
          flexWrap="wrap"
        >
          {feed.Reports.length ? (
            feed?.Reports?.map((report) => (
              <Flex margin="10px" width="100px" flexDirection="column">
                <Flex
                  width="100%"
                  justifyContent="center"
                  onClick={() => handleReportClick(report)}
                  css={{ cursor: "pointer" }}
                >
                  <Icon fontSize="84px" color="#ffca28" as={AiFillFolderOpen} />
                </Flex>
                <Flex marginTop="-10px" width="100%" justifyContent="center">
                  <Text>
                    {moment(report?.report_date).format("DD-MM-YYYY")}
                  </Text>
                </Flex>
              </Flex>
            ))
          ) : (
            <Text>No reports yet.</Text>
          )}
        </Flex>
      </Flex>
      <FeedModal
        type="property"
        onClose={onClose}
        isOpen={isOpen}
        feed={currentReport}
        refresh={refresh}
      />
    </Flex>
  );
};

export default PropertyCard;
