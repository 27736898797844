import axios from "axios";
import config from "../config/config";

export const paymentInfo = async (property_id, callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/user/paymentInfo`,
      params: {
        property_id,
      },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 400 ||
      error.response.status === 404 ||
      error.response.status === 401 ||
      error.response.status === 500
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const paymentResposne = async (body, callback) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/user/postStripeResponseWeb`,
      data: body,
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 400 ||
      error.response.status === 404 ||
      error.response.status === 401 ||
      error.response.status === 500
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};
