import axios from "axios";
import config from "../config/config";

export const getFeed = async (user_id, token, callback) => {
  try {
    let response = await axios({
      method: "GET",
      url: `${config.server.url}/user/getFeed`,
      params: {
        user_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 404 ||
      error.response.status === 500 ||
      error.response.status === 401 ||
      error.response.status === 400
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};

export const postRatingAndComment = async (
  user_id,
  id,
  subscriber_rating,
  subscriber_comment,
  type,
  token,
  callback
) => {
  try {
    let response = await axios({
      method: "POST",
      url: `${config.server.url}/user/postRatingAndComment`,
      data: {
        user_id,
        id,
        subscriber_rating,
        subscriber_comment,
        type,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      callback(null, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 400 ||
      error.response.status === 404 ||
      error.response.status === 401 ||
      error.response.status === 500
    ) {
      if (error.response.data) {
        callback(error.response.data, null);
      }
    }
  }
};
