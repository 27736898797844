import { createSlice } from "@reduxjs/toolkit";

export const compareSlice = createSlice({
  name: "compare",
  initialState: {
    images: [],
  },
  reducers: {
    addImage: (state, action) => {
      if (state.images.length === 8) {
        state.images.pop();
      }
      state.images.unshift(action.payload);
    },
    removeImage: (state, action) => {
      state.images = state.images.filter((e) => e.id !== action.payload);
    },
  },
});

export const { addImage, removeImage } = compareSlice.actions;

export default compareSlice.reducer;
