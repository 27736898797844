import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";

import techEyeLogo from "../../assets/techEyeLogo.png";

import { useParams } from "react-router-dom";
import { paymentInfo } from "../../modules/paymentModule";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../components/CheckoutForm/CheckoutForm";
import styles from "../../config/styles";

const Payment = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentInformation, setPaymentInfo] = useState("");
  const { propertyId } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setStripePromise(
      loadStripe(
        "pk_live_51MbMhmSG0YyBWqv5IeeAic0tqibuTQgtk07ANmVZCeP3tyJ7IJW5oTcZBt8h5n9A5IFPsCdHWMmOf806Ibs5du5x00mIlwJ616"
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    paymentInfo(propertyId, (error, details) => {
      if (error) {
        console.log(error);
        alert(`${error.code} - ${error.message}`);
      } else if (details) {
        setClientSecret(details.data?.razorpay_signature);
        setPaymentInfo(details.data);
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box background="-webkit-linear-gradient(bottom, rgb(230 230 230), rgb(15 81 193))">
      <Center>
        <Box
          width={["90vw", "90vw", "60vw", "40vw"]}
          borderRadius="5px"
          backgroundColor="white"
          padding="20px"
          marginTop="20px"
          marginBottom="20px"
        >
          <Flex justifyContent="center" marginBottom="20px">
            <Image src={techEyeLogo} alt="techEyeLogo" />
          </Flex>

          <Box>
            <Text marginBottom={"10px"} fontSize={styles.heading.fontSize}>
              Package Information :{" "}
            </Text>

            <Text>
              <b>Property Name: </b>
              {paymentInformation?.User_Property?.property_name}
            </Text>
            <Text>
              <b>Order Id: </b>
              {paymentInformation?.razorpay_order_id}
            </Text>
            <Text>
              <b>Duration: </b>
              {paymentInformation?.duration}
            </Text>
            <Text marginBottom={"10px"}>
              <b>Frequency: </b>
              {paymentInformation?.frequency}
            </Text>

            <Text>
              <b>Charges: </b>
            </Text>
            <Text>
              Geofencing Charges:&nbsp;
              {paymentInformation?.geo_fencing_charges}
            </Text>
            <Text>
              Subscription Charges:&nbsp;
              {paymentInformation?.subscription_charges}
            </Text>
            <hr />
            <Text float={"right"}>
              <b>Total: </b> {paymentInformation.currency} &nbsp;
              {paymentInformation?.total_amount / 100}
            </Text>

            <br />
            <br />
          </Box>

          <Box>
            {loading ? null : (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm />
              </Elements>
            )}
          </Box>
        </Box>
      </Center>
    </Box>
  );
};

export default Payment;
