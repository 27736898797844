import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { Box, Flex, Text } from "@chakra-ui/react";
import FeedCard from "../components/FeedCard/FeedCard";
import { useSelector } from "react-redux";
import { getFeed } from "../modules/homepageModule";

const Home = () => {
  const user = useSelector((state) => state?.user?.user);
  const token = useSelector((state) => state?.user?.token);
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getFeed(user.user_id, token, (error, details) => {
      if (error) {
        console.log(error);
        alert(`${error.code} - ${error.message}`);
      } else if (details) {
        setFeed(details.data);
        console.log(details.data);
        setLoading(false);
      }
    });
  }, []);

  const refresh = () => {
    getFeed(user.user_id, token, (error, details) => {
      if (error) {
        console.log(error);
        alert(`${error.code} - ${error.message}`);
      } else if (details) {
        setFeed(details.data);
      }
    });
  };

  return (
    <Box
      height={[
        "calc(90vh - 60px)",
        "calc(90vh - 60px)",
        "calc(90vh - 60px)",
        "90vh",
      ]}
      marginLeft={["0px", "0px", "0px", "20vw"]}
      padding={["10px", "10px", "10px", "20px 20px 20px 10px"]}
    >
      <Flex height="100%">
        {loading ? (
          <Box
            height="400px"
            width={"100%"}
            padding="10px 0px"
            background="#FFFFFF"
            borderRadius="10px"
          >
            <ContentLoader
              speed={2}
              width={900}
              height={700}
              viewBox="0 0 400 300"
              backgroundColor="#f3f3f3"
              foregroundColor="#ebebeb"
            >
              <rect x="50" y="6" rx="4" ry="4" width="343" height="38" />
              <rect x="8" y="6" rx="4" ry="4" width="35" height="38" />
              <rect x="50" y="55" rx="4" ry="4" width="343" height="38" />
              <rect x="8" y="55" rx="4" ry="4" width="35" height="38" />
              <rect x="50" y="104" rx="4" ry="4" width="343" height="38" />
              <rect x="8" y="104" rx="4" ry="4" width="35" height="38" />
            </ContentLoader>
          </Box>
        ) : (
          <Box
            width={["100%", "100%", "100%", "100%"]}
            overflowY="auto"
            height="100%"
            css={{
              "&::-webkit-scrollbar": {
                width: "10px",
              },
              "&::-webkit-scrollbar-track": {
                width: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "gray",
                borderRadius: "24px",
              },
            }}
          >
            {feed.map((item) => (
              <FeedCard refresh={refresh} feed={item} />
            ))}
          </Box>
        )}
        {/* <Spacer /> */}
        {/* <Hide below="md">
          <Box height="100%" width="25%">
            <Flex
              width="100%"
              height="calc(90vh - 40px)"
              borderRadius="10px"
              background="#FFFFFF"
              flexDirection="Column"
              padding="10px"
            >
              <Text fontSize="18px" fontWeight="600">
                Recent Notifications
              </Text>
            </Flex>
          </Box>
        </Hide> */}
      </Flex>
    </Box>
  );
};

export default Home;
