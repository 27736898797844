import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import FeedDetails from "../FeedDetails/FeedDetails";

const FeedModal = ({ type, onClose, isOpen, feed, refresh }) => {
  if (feed?.Reports_Details?.length) {
    const videos = feed.Reports_Details.filter(
      (item) => item?.Property_Medium?.media_type === "Video"
    );

    const photos = feed.Reports_Details.filter(
      (item) => item?.Property_Medium?.media_type === "Photo"
    );

    feed.Reports_Details = [...videos, ...photos];
  }

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size={["3xl", "3xl", "4xl", "5xl"]}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{type === "property" ? "Gallery" : "Feed"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody width="100%">
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 1,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {feed?.Reports_Details?.length ? (
              feed?.Reports_Details?.map((item) => (
                <FeedDetails
                  refresh={refresh}
                  item={item}
                  type={type}
                  report_date={feed?.report_date}
                />
              ))
            ) : (
              <div />
            )}
          </Carousel>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FeedModal;
