import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import "./App.css";

import Login from "./pages/Login";
import Home from "./pages/Home";
import Header from "./components/Header/Header";
import Sidebar from "./components/SideBar/Sidebar";
import Taskbar from "./components/TaskBar/Taskbar";
import Reports from "./pages/Reports/Reports";
import Property from "./pages/Property/Property";
import Comparison from "./pages/Comparison/Comparison";
import PrivateRoute from "./PrivateRoute";
import Payment from "./pages/Payment/Payment";

function App() {
  return (
    <Box width="100%" height="100vh" fontFamily="Poppins">
      <BrowserRouter>
        <Header />
        <Sidebar />
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route
            exact
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/reports"
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/properties"
            element={
              <PrivateRoute>
                <Property />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/comparison"
            element={
              <PrivateRoute>
                <Comparison />{" "}
              </PrivateRoute>
            }
          />
          <Route exact path="/payments/:propertyId" element={<Payment />} />
        </Routes>
        <Taskbar />
      </BrowserRouter>
    </Box>
  );
}

export default App;
