import React, { useState } from "react";
import {
  Flex,
  Image,
  Text,
  Button,
  useDisclosure,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";

import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { removeImage } from "../../redux/features/compareSlice";
import styles from "../../config/styles";
import { CloseIcon } from "@chakra-ui/icons";

const Comparison = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const images = useSelector((state) => state?.compare?.images);
  const [active, setActive] = useState([]);

  const handleImageDelete = (id) => {
    const temp = active.filter((e) => e !== id);
    setActive(temp);
    dispatch(removeImage(id));
  };

  const handleImageClick = (id) => {
    if (active.includes(id)) {
      const temp = active.filter((e) => e !== id);
      setActive(temp);
    } else {
      setActive([...active, id]);
    }
  };

  return (
    <Box
      height={[
        "calc(90vh - 60px)",
        "calc(90vh - 60px)",
        "calc(90vh - 60px)",
        "90vh",
      ]}
      marginLeft={["0px", "0px", "0px", "20vw"]}
      padding={["10px", "10px", "10px", "20px 20px 20px 10px"]}
    >
      <Flex
        background="#FFFFFF"
        borderRadius="10px"
        height="100%"
        padding="20px"
        flexDirection="column"
        overflowY="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            width: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray",
            borderRadius: "24px",
          },
        }}
      >
        <Text fontSize={styles.heading.fontSize}>Comparison Tool</Text>
        <Text color="maroon" marginTop="20px">
          Please click on 2 photos to select those and click on Compare button
          to see the result.
        </Text>
        <Flex
          flexWrap="wrap"
          marginTop="10px"
          marginBottom="20px"
          gap="10px 0.5%"
        >
          {images.length ? (
            images.map((item) => (
              <Flex
                alignItems="center"
                width={["50%", "50%", "24%", "24%"]}
                flexDirection="column"
                padding="5px"
                borderRadius="10px"
                background="#d6ecf3"
                opacity={active.includes(item?.id) ? 1 : 0.5}
              >
                <Flex width="100%" justifyContent="flex-end">
                  <Flex
                    width="40px"
                    height="40px"
                    borderRadius="50%"
                    background="#d6ecf3"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="2px"
                    marginRight={"2px"}
                    css={{ cursor: "pointer" }}
                    onClick={() => handleImageDelete(item?.id)}
                    zIndex={1}
                  >
                    <CloseIcon />
                  </Flex>
                </Flex>
                <Image
                  css={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleImageClick(item?.id)}
                  height="200px"
                  borderRadius={"5px"}
                  marginTop={"-42px"}
                  src={item?.Property_Medium?.media_url}
                />

                <Text
                  fontSize="14px"
                  fontWeight="600"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  {moment(item?.report_date).format("MMMM Do YYYY")}
                </Text>
                <Text fontSize="16px" fontWeight="600">
                  {item?.Capture_Point?.point_name}
                </Text>
              </Flex>
            ))
          ) : (
            <Text>
              No images to compare, please add atleast two images to proceed.
            </Text>
          )}
        </Flex>
        <Flex width="100%">
          <Button
            color="#ffffff"
            background={"#005cee"}
            isDisabled={active.length !== 2}
            onClick={onOpen}
            _hover={{}}
          >
            Compare
          </Button>
        </Flex>
        <Flex flexDirection={"column"} marginTop="20px">
          <Text fontSize={"18px"} fontWeight={600}>
            Note:
          </Text>
          <Text>1. You can add max 8 number of photos.</Text>
          <Text>2. You can select only two photos to compare.</Text>
        </Flex>
      </Flex>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={["3xl", "4xl", "4xl", "5xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Comparison</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            width="100%"
            display="flex"
            flexWrap="wrap"
            justifyContent="space-around"
            overflowY="auto"
          >
            {active.map((e) => (
              <Flex width={["90%", "90%", "48%", "48%"]}>
                {" "}
                <Flex
                  alignItems="center"
                  flexDirection="column"
                  marginBottom="20px"
                >
                  <Image
                    width="90%"
                    height={["70%", "70%", "100%", "100%"]}
                    src={
                      images.filter((item) => item.id === e)[0]?.Property_Medium
                        ?.media_url
                    }
                  />
                  <Text
                    fontSize="14px"
                    fontWeight="600"
                    color="rgba(0, 0, 0, 0.6)"
                  >
                    {moment(
                      images.filter((item) => item.id === e)[0]?.report_date
                    ).format("MMMM Do YYYY")}
                  </Text>
                  <Text fontSize="16px" fontWeight="600">
                    {
                      images.filter((item) => item.id === e)[0]?.Capture_Point
                        ?.point_name
                    }
                  </Text>
                </Flex>
              </Flex>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Comparison;
