import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../redux/features/userSlice";

import techEyeLogo from "../assets/techEyeLogo.png";

import { LockIcon, EmailIcon } from "@chakra-ui/icons";

import { useNavigate } from "react-router-dom";
import { login } from "../modules/authModule";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  console.log(user);
  useEffect(() => {
    if (user.token) {
      navigate("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    setLoading(true);

    try {
      login(email, password, (error, user) => {
        if (error) {
          alert(`${error.code} - ${error.message}`);
          setLoading(false);
        } else if (user) {
          console.log(user.data);
          dispatch(userLogin(user.data));
          setLoading(false);
          navigate("/home");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      height="100vh"
      background="-webkit-linear-gradient(bottom, rgb(230 230 230), rgb(15 81 193))"
    >
      <Center height="100vh">
        <Box
          width={["90vw", "70vw", "50vw", "30vw"]}
          borderRadius="5px"
          backgroundColor="white"
          padding="50px"
        >
          <Flex justifyContent="center" marginBottom="55px">
            <Image src={techEyeLogo} alt="techEyeLogo" />
          </Flex>

          <Box>
            <FormControl>
              <InputGroup>
                <InputLeftElement margin="10px 0px">
                  <EmailIcon />
                </InputLeftElement>
                <Input
                  id="email"
                  type="email"
                  placeholder="Email"
                  background="#e6e6e6"
                  fontSize="18px"
                  height="62px"
                  onChange={(event) => setEmail(event.currentTarget.value)}
                />
              </InputGroup>
            </FormControl>
            <FormControl marginTop="16px">
              <InputGroup>
                <InputLeftElement margin="10px 0px">
                  <LockIcon />
                </InputLeftElement>
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  background="#e6e6e6"
                  fontSize="18px"
                  height="62px"
                  onChange={(event) => setPassword(event.currentTarget.value)}
                />
                <InputRightElement margin="10px 0px">
                  {showPassword ? (
                    <Flex
                      css={{ cursor: "pointer" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <AiFillEye />
                    </Flex>
                  ) : (
                    <Flex
                      css={{ cursor: "pointer" }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <AiOutlineEye />
                    </Flex>
                  )}
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button
              color="white"
              marginTop="16px"
              background="rgb(15 81 193)"
              height="62px"
              width="100%"
              isLoading={loading}
              variant="solid"
              onClick={handleSubmit}
            >
              LOGIN
            </Button>
          </Box>
        </Box>
      </Center>
    </Box>
  );
};

export default Login;
