import React, { useEffect, useState } from "react";
import { Flex, Show, Text, Icon } from "@chakra-ui/react";
import {
  AiOutlinePicLeft,
  AiOutlineHome,
  AiOutlineFilePdf,
  AiOutlineSwitcher,
} from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";

const taskbarContent = [
  { name: "My Feed", icon: AiOutlinePicLeft, route: "/home" },
  { name: "Properties", icon: AiOutlineHome, route: "/properties" },
  { name: "View Reports", icon: AiOutlineFilePdf, route: "/reports" },
  { name: "Comparison", icon: AiOutlineSwitcher, route: "/comparison" },
];

const Taskbar = () => {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  return (
    <Show below="md">
      <Flex
        display={
          location.pathname === "/" ||
          location.pathname.split("/")[1] === "payments"
            ? "none"
            : "flex"
        }
        height="60px"
        borderTop="1px solid rgba(112, 112, 112, 0.2)"
        borderRadius="10px 10px 0px 0px"
        boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
        padding="10px"
        background="#FFFFFF"
        justifyContent="space-around"
      >
        {taskbarContent.map((item) => (
          <Flex
            as={Link}
            to={item.route}
            key={item.name}
            alignItems="center"
            flexDirection="Column"
            color={active === item.route ? "#26A6D1" : "#707070"}
            css={{
              ":hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => setActive(item.route)}
          >
            <Icon margin="0px 15px" as={item.icon} />
            <Text fontSize="12px">{item.name}</Text>
          </Flex>
        ))}
      </Flex>
    </Show>
  );
};

export default Taskbar;
