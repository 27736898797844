import React, { useState } from "react";
import {
  Flex,
  Image,
  Text,
  Button,
  FormControl,
  Input,
  Divider,
  Icon,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AiFillStar,
  AiOutlineAppstoreAdd,
  AiOutlineStar,
  AiOutlineZoomIn,
} from "react-icons/ai";

import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { addImage } from "../../redux/features/compareSlice";
import { Link } from "react-router-dom";
import { postRatingAndComment } from "../../modules/homepageModule";

const FeedDetails = ({ item: feed, report_date, refresh, type }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const token = useSelector((state) => state?.user?.token);
  const images = useSelector((state) => state?.compare?.images);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [showComment, setShowComment] = useState("");

  const handleAddToCompare = () => {
    const newImage = { ...feed, report_date };

    if (images.filter((item) => item.id === newImage.id).length) {
      alert("Image already added to compare tool.");
      return;
    } else {
      onOpen();
      dispatch(addImage(newImage));
    }
  };

  const postRatingNComment = () => {
    if (!rating || !comment) {
      alert("Please enter both rating and comment.");
      return;
    }
    postRatingAndComment(
      user.user_id,
      feed.id,
      rating,
      comment,
      "report_detail",
      token,
      (error, details) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (details) {
          alert("Posted succesfully!");
          if (type === "property") {
            console.log(type, "Added");
            setShowComment(comment);
          }
          refresh();
        }
      }
    );
  };

  return (
    <Flex width="100%" flexDirection="column">
      <Flex justifyContent="center">
        {feed?.Property_Medium?.media_type === "Photo" ? (
          <Image height="400px" src={feed?.Property_Medium?.media_url} />
        ) : null}
        {feed?.Property_Medium?.media_type === "Video" ? (
          <Flex height="400px" justifyContent="center">
            <video controls>
              <source src={feed?.Property_Medium?.media_url} type="video/mp4" />
            </video>
          </Flex>
        ) : null}
      </Flex>
      <Flex padding="20px">
        <Flex width="50%" flexDirection="column">
          <Text fontSize="18px" fontWeight="600" color="rgba(0, 0, 0, 0.6)">
            {moment(report_date).format("MMMM Do YYYY")}
          </Text>
          <Text fontSize="22px" fontWeight="600">
            {feed?.Capture_Point?.point_name}
          </Text>
          <Text>{feed?.Capture_Point_Message?.message}</Text>
        </Flex>
        <Flex width="50%" justifyContent="flex-end">
          {feed?.Property_Medium?.media_type === "Photo" ? (
            <Flex flexWrap="wrap">
              <Button color="#005cee" marginRight="5px">
                <a
                  href={feed?.Property_Medium?.media_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon marginBottom="-2px" as={AiOutlineZoomIn} />
                  &nbsp;Full-Screen
                </a>
              </Button>
              <Button color="#005cee" onClick={() => handleAddToCompare()}>
                <Icon marginBottom="-2px" as={AiOutlineAppstoreAdd} />
                &nbsp;Add to compare
              </Button>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
      <Divider />
      <Flex padding="20px" flexDirection="column">
        {feed?.single_report_subscriber_rating || rating ? (
          <Flex marginBottom="10px" alignItems="center">
            <Text>
              <b>Your Rating : </b>
            </Text>
            <Flex>
              {Array.apply(null, {
                length: feed?.single_report_subscriber_rating || rating,
              }).map((e, i) => (
                <Icon fontSize="20px" color="#fcd53f" as={AiFillStar} />
              ))}
            </Flex>
          </Flex>
        ) : (
          <Flex marginBottom="10px" alignItems="center">
            <Text>Rate :&nbsp;</Text>
            <Flex>
              {Array.apply(null, {
                length: 5,
              }).map((e, i) => (
                <Icon
                  fontSize="20px"
                  css={{ cursor: "pointer" }}
                  onClick={() => setRating(i + 1)}
                  color="#fcd53f"
                  as={AiOutlineStar}
                />
              ))}
            </Flex>
          </Flex>
        )}

        {feed?.single_report_subscriber_comment || showComment ? (
          <Flex>
            <Text>
              <b>Your Comment : </b>
              {feed?.single_report_subscriber_comment || showComment}
            </Text>
          </Flex>
        ) : (
          <Flex>
            <FormControl>
              <Input
                paddingLeft="5px"
                type="text"
                placeholder="Write a  comment ..."
                onChange={(e) => setComment(e.target.value)}
              />
            </FormControl>
            <Button
              onClick={() => postRatingNComment()}
              marginLeft="10px"
              borderRadius="10px"
              background="#FFFFFF"
            >
              POST
            </Button>
          </Flex>
        )}
      </Flex>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Comparison Tool</DrawerHeader>

          <DrawerBody overflowY="auto">
            {images.map((item) => (
              <Flex
                alignItems="center"
                flexDirection="column"
                marginBottom="20px"
              >
                <Image height="150px" src={item?.Property_Medium?.media_url} />
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  color="rgba(0, 0, 0, 0.6)"
                >
                  {moment(item?.report_date).format("MMMM Do YYYY")}
                </Text>
                <Text fontSize="16px" fontWeight="600">
                  {item?.Capture_Point?.point_name}
                </Text>
              </Flex>
            ))}
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" as={Link} to="/comparison">
              Go to Compare
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default FeedDetails;
