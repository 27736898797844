import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Icon, Select, Button, Link } from "@chakra-ui/react";
import { AiOutlineFilePdf } from "react-icons/ai";

import { useSelector } from "react-redux";
import styles from "../../config/styles";
import { getReports } from "../../modules/reportsModule";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { SearchIcon } from "@chakra-ui/icons";
import config from "../../config/config";

const Reports = () => {
  const user = useSelector((state) => state?.user?.user);
  const token = useSelector((state) => state?.user?.token);
  const [recentReports, setRecentReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([
    "All",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ]);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState("All");

  useEffect(() => {
    setLoading(true);
    getReports(user.user_id, null, null, token, (error, reports) => {
      if (error) {
        console.log(error);
        alert(`${error.code} - ${error.message}`);
      } else if (reports) {
        setRecentReports(reports.data);
      }
    });
    let tempYears = [];
    for (let i = moment().year(); i > 2019; i--) {
      tempYears.push(String(i));
    }
    setYears(tempYears);

    getReports(
      user.user_id,
      moment().year(),
      "All",
      token,
      (error, reports) => {
        if (error) {
          console.log(error);
          alert(`${error.code} - ${error.message}`);
        } else if (reports) {
          setFilteredReports(reports.data);
          setLoading(false);
        }
      }
    );
  }, []);

  const handleSearch = () => {
    setLoading(true);
    getReports(user.user_id, year, month, token, (error, reports) => {
      if (error) {
        console.log(error);
        alert(`${error.code} - ${error.message}`);
      } else if (reports) {
        setFilteredReports(reports.data);
        setLoading(false);
      }
    });
  };

  return (
    <Box
      height={[
        "calc(90vh - 60px)",
        "calc(90vh - 60px)",
        "calc(90vh - 60px)",
        "90vh",
      ]}
      marginLeft={["0px", "0px", "0px", "20vw"]}
      padding={["10px", "10px", "10px", "20px 20px 20px 10px"]}
    >
      <Flex
        background="#FFFFFF"
        borderRadius="10px"
        height="100%"
        padding="20px"
      >
        {loading ? (
          <Box
            height="400px"
            width={"100%"}
            padding="10px 0px"
            background="#FFFFFF"
            borderRadius="10px"
          >
            <ContentLoader
              speed={2}
              width={900}
              height={700}
              viewBox="0 0 400 300"
              backgroundColor="#f3f3f3"
              foregroundColor="#ebebeb"
            >
              <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
              <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
              <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
              <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
              <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
              <circle cx="20" cy="20" r="20" />
            </ContentLoader>
          </Box>
        ) : (
          <Flex flexDirection="column" width="100%" overflowY="auto">
            <Flex flexDirection="column" width="100%">
              <Text fontSize={styles.heading.fontSize}>Recent Reports</Text>
              <Flex gap="10px 0.5%" flexWrap="wrap">
                {recentReports.length ? (
                  recentReports.map((item) => (
                    <Link
                      href={`${item?.report_url}`}
                      isExternal
                      display="flex"
                      borderRadius="5px"
                      background="#FCFDFF"
                      boxShadow="1px 1px 5px #E2E8F0"
                      width={["100%", "100%", "48%", "33%"]}
                      height="80px"
                      marginTop="20px"
                      alignItems="center"
                    >
                      <Flex marginLeft="10px">
                        <Icon
                          fontSize="54px"
                          color="#8C1313"
                          as={AiOutlineFilePdf}
                        />
                      </Flex>
                      <Flex flexDirection="column" marginLeft="10px">
                        <Text
                          fontSize="14px"
                          fontWeight="500"
                          color="rgba(0, 0, 0, 0.6)"
                        >
                          {moment(item?.report_generated_date).format(
                            "MMMM Do YYYY"
                          )}
                        </Text>
                        <Text fontSize="20px">
                          {item?.User_Property?.property_name}
                        </Text>
                      </Flex>
                    </Link>
                  ))
                ) : (
                  <Text
                    fontSize="14px"
                    marginTop="20px"
                    fontWeight="500"
                    color="rgba(0, 0, 0, 0.6)"
                  >
                    No reports to show.
                  </Text>
                )}
              </Flex>
            </Flex>
            <Flex flexDirection="column" width="100%" marginTop="20px">
              <Text fontSize={styles.heading.fontSize}>Archives</Text>
              <Flex alignItems="center">
                <Text>Year</Text>
                <Select
                  marginLeft="10px"
                  width="100px"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
                <Text marginLeft="10px">Month</Text>
                <Select
                  marginLeft="10px"
                  width="100px"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </Select>
                <Button
                  marginLeft="10px"
                  color="#005cee"
                  onClick={() => handleSearch()}
                >
                  <SearchIcon />
                </Button>
              </Flex>
              <Flex gap="10px 0.5%" flexWrap="wrap">
                {filteredReports.length ? (
                  filteredReports.map((item) => (
                    <Link
                      href={`${item?.report_url}`}
                      isExternal
                      display="flex"
                      borderRadius="5px"
                      background="#FCFDFF"
                      boxShadow="1px 1px 5px #E2E8F0"
                      width={["100%", "100%", "48%", "33%"]}
                      height="80px"
                      marginTop="20px"
                      alignItems="center"
                    >
                      <Flex marginLeft="10px">
                        <Icon
                          fontSize="54px"
                          color="#8C1313"
                          as={AiOutlineFilePdf}
                        />
                      </Flex>
                      <Flex flexDirection="column" marginLeft="10px">
                        <Text
                          fontSize="14px"
                          fontWeight="500"
                          color="rgba(0, 0, 0, 0.6)"
                        >
                          {moment(item?.report_generated_date).format(
                            "MMMM Do YYYY"
                          )}
                        </Text>
                        <Text fontSize="20px">
                          {item?.User_Property?.property_name}
                        </Text>
                      </Flex>
                    </Link>
                  ))
                ) : (
                  <Text
                    fontSize="14px"
                    marginTop="20px"
                    fontWeight="500"
                    color="rgba(0, 0, 0, 0.6)"
                  >
                    No reports to show.
                  </Text>
                )}
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default Reports;
